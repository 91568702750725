<template>
  <div>
    <div @dragover.prevent @drop.prevent>
      <div class="row form">
        <div class="col-4">
          <v-select
            label="Tipo"
            placeholder="Selecionar"
            v-model="type"
            v-bind:options="typeOptions"
            v-on:onChange="value => (this.type = value)"
            @input="typeChanged"
          />
        </div>
        <div class="col-4" v-if="type === 'migration'">
          <v-select
            label="Tabela"
            placeholder="Selecionar"
            v-model="migrationType"
            v-bind:options="migrationTypeOptions"
            v-on:onChange="value => (this.migrationType = value)"
          />
        </div>
        <div class="col-4" v-if="type === 'tiss'">
          <v-select
            label="Tabela"
            placeholder="Selecionar"
            v-model="tissType"
            v-bind:options="tissTypeOptions"
            v-on:onChange="value => (this.tissType = value)"
          />
        </div>
        <div class="col-2" v-if="tissType === 'procedure'">
          <v-select
            label="Origem"
            placeholde="Selecionar"
            v-model="source"
            :options="sourceOptions"
            @onChange="value => this.source = value"/>
        </div>
        <b-col cols="5" v-if="tissActive">
          <b-form-group>
            <label for="name" class="float-left">Nome da tabela</label>
            <b-input type="text" id="name" v-model="name" />
          </b-form-group>
        </b-col>
        <b-col cols="3" v-if="tissActive">
          <b-form-group>
            <v-select
              label="Comunicado oficial"
              placeholder="Selecionar"
              v-model="officialCommunication"
              :options="officialCommunicationOptions"
            />
          </b-form-group>
        </b-col>
      </div>
      <div @drop="dragFile" class="area" @click="$refs.FileInput.click()">
        <div v-if="!file">Clique aqui ou solte o arquivo nessa área para selecionar...</div>
        <div v-if="file" class="file-name">{{ file.name }}</div>
        <div>
          <b-button
            class="buttons"
            v-if="file"
            variant="outline"
            @click="clearSelectedFile"
          >
            Cancelar
          </b-button>
          <b-button
            class="buttons"
            v-if="file && !tissActive"
            variant="primary"
            @click="uploadFile"
          >
            Enviar
          </b-button>
          <b-button
            class="buttons"
            v-else-if="file"
            variant="primary"
            @click="uploadFileTiss"
          >
            Enviar
          </b-button>
        </div>
      </div>
      <b-table-simple striped v-if="failures.length" class="failures">
        <tbody>
          <tr>
            <th scope="col">Linha</th>
            <th scope="col">Mensagem de erro</th>
          </tr>
          <tr v-for="(failure, index) of failures" :key="index">
            <td class="p-3">
              {{ failure.row }}
            </td>
            <td>{{ failure.errors.join('; ') }}</td>
          </tr>
        </tbody>
      </b-table-simple>
      <input
        ref="FileInput"
        type="file"
        style="display: none"
        accept=".csv,.xlsx"
        @change="onFileSelect"
      />

      <v-success-modal :message="message" />
    </div>
    <div class="all-imports">
      <div v-for="csvimport in imports" :key="csvimport.id" class="progress-content">
        <div class="filename">
          <div class="teste">
            <span><strong>{{ getType(csvimport.type) }}:</strong><br> {{ csvimport.file_name }}</span>
            <span><strong>Andamento:</strong><br> {{csvimport.processed_rows_count.toLocaleString()}} de {{csvimport.total_rows_count.toLocaleString()}}</span>
            <span><strong>Falhas:</strong><br> {{ csvimport.errors_count.toLocaleString() }}</span>
          </div>
          <b-progress :max="csvimport.total_rows_count" height="2rem" :animated="csvimport.finished_at === null">
            <b-progress-bar :value="csvimport.processed_rows_count - csvimport.errors_count" show-value variant="success"></b-progress-bar>
            <b-progress-bar :value="csvimport.errors_count" show-value  variant="danger"></b-progress-bar>
            <b-progress-bar :value="csvimport.total_rows_count - csvimport.processed_rows_count" show-value variant="secondary"></b-progress-bar>
          </b-progress>
        </div>
        <div v-if="csvimport.endForecast" class="time-result">
          Previsão de término em {{ csvimport.endForecast }}
          (~ {{ Math.round(csvimport.rowsPerSecond*100)/100 }} linhas por segundo)
        </div>
        <div v-if="csvimport.finished_at" class="time-result">
          Finalizado em {{ moment(csvimport.finished_at).diff(moment(csvimport.created_at), 'minutes') }} minutos
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import SuccessModal from '@/components/General/SuccessModal'
import Select from '@/components/Select'
export default {
  props: {
    clinic: Object,
  },
  components: {
    'v-success-modal': SuccessModal,
    'v-select': Select,
  },
  data() {
    return {
      type: null,
      source: null,
      tissType: null,
      migrationType: null,
      file: null,
      failures: [],
      errors: [],
      imports: [],
      message: null,
      typeOptions: [
        { label: 'Selecionar', value: null },
        { label: 'Eventos Calendly', value: 'calendly' },
        { label: 'Cadastros Banco Modal', value: 'google-forms-modal' },
        { label: 'Tabela TISS', value: 'tiss_table' },
        { label: 'Migração', value: 'migration'},
        { label: 'Cadastros Planos de saúde', value: 'health-plans' },
        { label: 'Cadastros Padrão TISS', value: 'tiss' },
      ],
      migrationTypeOptions: [
        { label: 'Atendimentos', value: 'attendance' },
        { label: 'Atualizar atendimentos', value: 'attendance-update' },
        { label: 'Convenios de Pessoas', value: 'person-health-plan' },
        { label: 'Endereços', value: 'address' },
        { label: 'Estoque', value: 'warehouse' },
        { label: 'Pessoas', value: 'person' },
      ],
      tissTypeOptions: [
        { label: 'Demais terminologias', value: 'tiss_others'},
        { label: 'Diárias e Taxas', value: 'tax'},
        { label: 'Materiais e OPME', value: 'material'},
        { label: 'Medicamentos', value: 'medicine'},
        { label: 'Procedimentos e Eventos', value: 'procedure'},
      ],
      sourceOptions: [
        { label: 'TUSS', value: 'TUSS' },
        { label: 'SIGTAP', value: 'SIGTAP' },
      ],
      tissActive: false,
      name: '',
      officialCommunication: '',
      officialCommunicationOptions: [],
      clinicForTiss: JSON.parse(localStorage.getItem('clinic')),
      migrationInterval: null,
    }
  },
  mounted() {
    const secondChannel = this.pusher.subscribe(
      `csvimport-tiss-table-${this.clinicForTiss.id}`
    )
    secondChannel.bind('updatedInProgress', this.updateImportProgress, this)
  },
  beforeDestroy() {
    if(this.migrationInterval)
      clearInterval(this.migrationInterval);
  },
  methods: {
    moment,
    async typeChanged() {
      this.tissActive = this.type === 'tiss_table';
      if (this.tissActive) {
        await this.getCBHPMAnnouncements()
      }
    },
    async getCBHPMAnnouncements() {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getCBHPMAnnouncements();
        Object.keys(response.data).forEach((key) => {
          this.officialCommunicationOptions.push({ label: key, value: key })
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    getType(type) {
      let typeFound = this.typeOptions.find(t => t.value === type);
      if (typeFound) return typeFound.label;
      typeFound = this.tissTypeOptions.find(t => t.value === type);
      if (typeFound) return typeFound.label;
      typeFound = this.migrationTypeOptions.find(t => t.value === type);
      if (typeFound) return 'Migração - ' + typeFound.label;
      return 'Type not found';
    },
    onFileSelect(e) {
      const file = e.target.files[0];
      this.file = file;
    },
    dragFile(e) {
      const fileType = e.dataTransfer.files[0].type;
      if ([
          'text/csv',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ].includes(fileType)) {
        this.file = e.dataTransfer.files[0];
      } else {
        return this.$toast.error('Formato de arquivo não suportado!');
      }
    },
    uploadFile(ev) {
      ev.stopPropagation();
      this.failures = [];
      this.errors =[];

      if (!this.type) {
        this.$toast.error('Informe o tipo de importação!');
        return;
      }

      if(!this.clinic) {
        this.$toast.error('Selecione a clínica!');
        return;
      }

      if(this.type === 'migration' && !this.migrationType) {
        this.$toast.error('Selecione a tabela da migração!')
        return;
      }

      if(this.type === 'tiss' && !this.tissType) {
        this.$toast.error('Selecione a tabela tiss!')
        return;
      }

      if(this.tissType === 'procedure' && !this.source) {
        this.$toast.error('Selecione a origem!');
        return;
      }

      const dataForm = new FormData();
      dataForm.append(`csv-file`, this.file);
      dataForm.append(`fileName`, this.file.name);
      const finalType = this.type === 'tiss' ?  this.tissType : (this.type === 'migration' ?  this.migrationType : this.type)
      const params = {
        type: finalType,
        source: this.source
      };
      const isLoading = this.$loading.show();
      this.api.uploadCsvFile(this.clinic.id, params, dataForm)
      .then(res => {
        this.failures = res.data.failures || [];
        this.errors = res.data.errors;
        this.message = 'Arquivo enviado com sucesso';
        this.$bvModal.show('success-modal');
        this.file = null;
        this.type = null;
        this.migrationType = null;
        this.tissType = null;
        this.source = null;
        this.getPendingImports(this.clinic.id)
      })
      .catch((error) => {
        alert(error.response.data.message);
      })
      .finally(() => { isLoading.hide(); });
    },
    async uploadFileTiss(ev) {
      ev.stopPropagation()
      if(!this.name) {
        this.$toast.error('Preencha o nome!');
        return;
      }
      const isLoading = this.$loading.show();
      try {
        const dataForm = new FormData()
        dataForm.append('file', this.file)
        dataForm.append('name', this.name)
        dataForm.append('clinic_id', this.clinicForTiss.id)
        dataForm.append('cbhpm_announcement', this.officialCommunication)
        await this.api.uploadTissTable(dataForm)
        this.file = null
        this.type = null;
        this.message = 'Arquivo enviado com sucesso';
        this.$bvModal.show('success-modal');
        this.getPendingImports(this.clinicForTiss.id)
        this.$toast.success('Importação da tabela em andamento...')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    clearSelectedFile(ev) {
      ev.stopPropagation();
      this.file = null;
    },
    getPendingImports(clinicId = this.clinic.id) {
      this.api.getImportsByClinicId(clinicId)
      .then(res => {
        this.imports = res.data.sort((a,b) => new Date(b.created_at) - new Date(a.created_at));
      })
      .catch(error => {
        alert(error.response.data.message);
      })
    },
    updateImportProgress(data) {
      const csvImportIndex = this.imports.findIndex(csvimport => csvimport.id === data.id);
      const csvImport = csvImportIndex !== -1 ? this.imports[csvImportIndex] : null;
      if (csvImport && csvImport.processed_rows_count > data.processed_rows_count)  return;
      if (csvImport && !csvImport.finished_at) {
        const elapsedSeconds = moment().diff(moment(data.created_at), 'seconds');
        data.rowsPerSecond = data.processed_rows_count / elapsedSeconds;
        const secondsToEnd = (data.total_rows_count - data.processed_rows_count) / data.rowsPerSecond;

        data.endForecast = moment()
          .add(secondsToEnd, 'seconds')
          .format('DD/MM/YYYY HH:mm:ss');
      }
      this.imports.splice(csvImportIndex, 1, data);
    }
  },
  watch: {
    clinic: function(newClinic, oldClinic) {
      this.getPendingImports()
      if(this.migrationInterval)
        clearInterval(this.migrationInterval);
      this.migrationInterval = setInterval(() => {
        this.getPendingImports()
      }, 30000);
    }
  }
}
</script>
<style lang="scss" scoped>
.form {
  padding: 40px 20px;
}
.area {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 300px;
  border: 1px dashed;
  border-radius: 5px;
  cursor: pointer;
}
.file-name {
  padding: 10px;
  color: var(--type-placeholder);
}
.buttons {
  margin: 10px;
}

.failures {
  margin-top: 40px;

  td, th {
    text-align: left;
  }
}

.all-imports {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 50%;
  gap: 20px;
  margin: 20px;
  font-size: 0.95em;
}

.progress-content {
  border-radius: 8px;
  padding: 20px;
  background-color: var(--neutral-100);
}

.filename {
  text-align: left;
}

.teste {
  display: flex;
  justify-content: space-between;

  span:first-child {
    width: 50ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.time-result {
  text-align: center;
  margin: 10px 0;
}

.progress > div:nth-child(3) {
  background-color: lightgray !important;
  color: #222;
}
</style>
